import * as React from 'react';
import lolight from 'lolight';
import './SyntaxHighlighter.css';

interface Props {
    code: string;
}

export default function SyntaxHighlighter({ code }: Props) {
    const tokens = lolight.tok(code).map((token: string[] ) => {
        return (
            <span className={ `ll-${ token[0] }` }>
                { token[1] }
            </span>
        )
    });

    return (
        <div className="font-mono bg-gray-900 text-gray-100 py-2 px-6 my-4 leading-loose overflow-scroll whitespace-pre">
            { tokens }
        </div>
    )
}
