import * as React from 'react';
import { Link } from 'react-navi';

interface Props {
    children: React.ReactNode;
    href: string;
}

function NavigationItem({ children, href }: Props) {
    const activeClasses = 'text-white bg-gray-900 hover:text-gray-300 hover:bg-gray-900 focus:bg-gray-900';
    const classes = 'ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-600 focus:outline-none focus:text-white focus:bg-gray-600';

    return (
        <Link activeClassName={ activeClasses } className={ classes } href={ href }>
            { children }
        </Link>
    );
}

export default NavigationItem;
