import * as React from 'react';
import classNames from 'classcat';
import InfoIcon from '../icons/InfoIcon';

interface Props {
    className?: string;
    message: string;
}

function Alert({ className, message }: Props) {
    const classes = classNames({
        [className || '']: true,
        'rounded-md bg-blue-50 p-4': true
    });

    return (
        <div className={ classes }>
            <div className="flex">
                <div className="flex-shrink-0">
                    <InfoIcon className="h-5 w-5 text-blue-400" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm leading-5 text-blue-700">
                        { message }
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Alert;
