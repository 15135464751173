import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { AccountApiKeyCreateResponse } from './accountReducer';
import Result from '../common/Result';
import Button from '../common/Button';
import { sendNotification } from '../notifications/notificationReducer';
import SyntaxHighlighter from '../clusters/SyntaxHighlighter';
import ClipboardIcon from '../icons/ClipboardIcon';

interface Props {
    apiKey: AccountApiKeyCreateResponse | null;

    sendNotification: typeof sendNotification;
}

function AccountApiKeysCreateResult({ apiKey, sendNotification }: Props) {
    // TODO: This should never happen I think? Does it happen on errors?
    if (apiKey == null) {
        return null;
    }

    const sendClipboardNotification = () => {
        sendNotification({
            duration: 3000,
            title: 'Copied',
            message: 'Copied key to clipboard',
            type: 'success'
        })
    }

    return (
        <Result extra={
            <div className="flex items-center">
                <SyntaxHighlighter code={ apiKey.key } />
                <CopyToClipboard text={ apiKey.key } onCopy={ sendClipboardNotification }>
                    <Button className="ml-4" type="secondary">
                        <ClipboardIcon className="h-5 mr-2" />

                        <span>Copy to clipboard</span>
                    </Button>
                </CopyToClipboard>
            </div>
        } icon={
            <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        } title={
            <span>Your API key <strong>{ apiKey.name }</strong> has been created!</span>
        } subtitle={
            "Please ensure you copy this key somewhere secure, as it won't be shown again."
        } />
    )
}

const mapDispatchToProps = ({
    sendNotification
})

export default connect(null, mapDispatchToProps)(AccountApiKeysCreateResult);
