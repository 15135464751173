import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { handleError } from '../utils/errors';
import { fetchAuthed } from '../utils/ajax';

export interface TeamMember {
    id: string;
    name: string;
    email: string;
    addedAt: string;
}

export interface TeamState {
    members: TeamMember[];
    membersLoading: boolean;
}

const initialState: TeamState = {
    members: [],
    membersLoading: false,
}

export interface InviteTeamMemberRequest {
    email: string;
}

export const loadMembers = createAsyncThunk<TeamMember[]>('LoadTeamMembers', async (payload, thunk) => {
    try {
        return await fetchAuthed('/api/team/users');
    } catch (e) {
        handleError(e, 'loading your team members');
    }
});

export const removeMember = createAsyncThunk<void, string>('RemoveTeamMember', async (payload, thunk) => {
    try {
        await fetchAuthed('/api/team/users/' + payload, {
            method: 'DELETE'
        });

        // Reload the member list after removing the member
        thunk.dispatch(loadMembers());
    } catch (e) {
        handleError(e, 'removing the team member');
    }
});

export default createReducer(initialState, builder => builder
    .addCase(loadMembers.pending, state => {
        return {
            ...state,
            membersLoading: true
        }
    })
    .addCase(loadMembers.fulfilled, (state, action) => {
        return {
            ...state,
            membersLoading: false,
            members: action.payload
        }
    })
    .addCase(removeMember.pending, state => {
        return {
            ...state,
            membersRemoving: true
        }
    })
    .addCase(removeMember.fulfilled, state => {
        return {
            ...state,
            membersRemoving: false
        }
    })
);
