import * as React from 'react';
import Pluralize from 'react-pluralize';
import { connect } from 'react-redux';
import classNames from 'classcat';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Cluster } from './Cluster';
import { capitalize } from '../utils/formatting';
import { terminateCluster } from './clusterReducer';
import Badge from '../common/Badge';
import { useNavigation } from 'react-navi';
import { useState } from 'react';
import { RootState } from '../store';
import Table from '../common/Table';

interface TerminateModalProps {
    cluster: Cluster;
    loading: boolean;

    terminateCluster(id: string): void;

    onClose(): void;
}

function TerminateModal({ cluster, loading, onClose, terminateCluster }: TerminateModalProps) {
    const onOk = async () => {
        await terminateCluster(cluster.id);
        await onClose();
    };

    return (
        <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
                className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
                role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div
                            className="mx-auto mt-1 mr-3 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                Are you sure?
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm leading-5 text-gray-500">Early termination of a cluster will
                                    instantly delete all resources, but you will still be billed for the full cluster
                                    duration of <Pluralize singular="hour" count={ cluster.duration } />.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto ml-2">
                        <button type="button"
                                onClick={ () => onOk() }
                                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Terminate
                        </button>
                    </span>
                    <span className="flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button type="button"
                                onClick={ onClose }
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Cancel
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
}

interface Props {
    clusters: Cluster[];
    clusterTerminating: boolean;
    loading: boolean;

    terminateCluster(id: string): void;
}

function ClustersPageContent({ clusters, clusterTerminating, loading, terminateCluster }: Props) {
    const navigation = useNavigation();
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);

    const columns = [
        {
            key: 'name',
            name: 'Name',
        },
        {
            key: 'status',
            name: 'Status',
            width: '10rem',
            render: (row: Cluster) => {
                let headerTagColor;
                let headerTagIcon;
                switch (row.status) {
                    case 'active':
                        headerTagColor = 'text-green-400';
                        headerTagIcon =
                            <svg className="mr-1.5 h-3 w-3" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                 strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        break;
                    case 'pending':
                        headerTagColor = 'text-blue-500';
                        headerTagIcon = <svg className="mr-1.5 h-3 w-3 spinning" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                            <path
                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                        </svg>
                        break;
                    case 'terminated':
                        headerTagColor = 'text-gray-400';
                        headerTagIcon =
                            <svg className="mr-1.5 h-3 w-3" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                 strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        break;
                    case 'terminating':
                        headerTagColor = 'text-gray-400';
                        headerTagIcon = <svg className="mr-1.5 h-3 w-3 spinning" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                            <path
                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                        </svg>
                        break;
                    default:
                        headerTagColor = 'text-gray-400';
                        headerTagIcon = null;
                        break;
                }

                return <Badge color={ headerTagColor }>{ headerTagIcon } { capitalize(row.status) }</Badge>
            }
        },
        {
            key: 'createdAt',
            name: 'Created At',
            width: '15rem',
            render: (row: Cluster) => {
                if (row.createdAt) {
                    return (
                        <time dateTime={ row.createdAt }
                              title={ row.createdAt }>{ formatDistanceToNow(parseISO(row.createdAt), { addSuffix: true }) }</time>
                    )
                }

                return null;
            }
        },
        {
            key: 'expiresAt',
            name: 'Expires',
            width: '15rem',
            render: (row: Cluster) => {
                if (row.expiresAt) {
                    return (
                        <time dateTime={ row.expiresAt }
                              title={ row.expiresAt }>{ formatDistanceToNow(parseISO(row.expiresAt), { addSuffix: true }) }</time>
                    )
                }

                return null;
            }
        },
        {
            key: 'actions',
            width: '15rem',
            render: (row: Cluster) => {
                const onClickConnect = async () => {
                    // Do nothing if the cluster is already terminated
                    if (row.status === 'terminated') {
                        return;
                    }

                    await navigation.navigate('/clusters/' + row.id);
                }

                const onClickTerminate = () => {
                    // Do nothing if the cluster is already terminated
                    if (row.status === 'terminated') {
                        return;
                    }

                    setSelectedCluster(row);
                    setModalOpen(true);
                };

                const connectClasses = classNames({
                    'mr-5': true,
                    'text-blue-500 hover:text-blue-400 transition duration-150 ease-in-out': row.status === 'active',
                    'text-gray-300 cursor-not-allowed': row.status !== 'active',
                });

                const terminateClasses = classNames({
                    'text-red-500 hover:text-red-400 transition duration-150 ease-in-out': !row.status.startsWith('terminat'),
                    'text-gray-300 cursor-not-allowed': row.status.startsWith('terminat'),
                });

                return (
                    <>
                        <button className={ connectClasses } onClick={ onClickConnect }>
                            Connect
                        </button>

                        <button className={ terminateClasses } onClick={ onClickTerminate }>
                            Terminate
                        </button>
                    </>
                );
            }
        }
    ];

    const modal = isModalOpen && selectedCluster
        ? <TerminateModal cluster={ selectedCluster } loading={ clusterTerminating } onClose={ () => setModalOpen(false) }
                          terminateCluster={ terminateCluster } />
        : null;

    return (
        <>
            { modal }

            <Table columns={ columns } data={ clusters } />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    clusterTerminating: state.cluster.clusterTerminating
});

const mapDispatchToProps = ({
    terminateCluster
});

export default connect(mapStateToProps, mapDispatchToProps)(ClustersPageContent);
