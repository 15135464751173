import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { handleError } from '../utils/errors';
import { fetchAuthed } from '../utils/ajax';
import { RootState } from '../store';

export interface AccountApiKey {
    createdAt: string;
    id: string;
    isRevoked: boolean;
    name: string;
    key: string | null;
}

export interface AccountApiKeyCreateResponse {
    id: string;
    name: string;
    key: string;
}

export interface AccountInvoiceItem {
    name: string;
    cost: number;
    duration: number;
}

export interface AccountInvoice {
    id: string;
    createdAt: string;
    items: AccountInvoiceItem[];
    total: number;
}

export interface AccountPlan {
    id: string;
    name: string;
    cost: number;
    credits: number;
    points: string[];
}

export interface AccountTeam {
    id: string;
    name: string;
    isSubscribed: boolean;
}

export interface Account {
    id: string;
    email: string;
    name: string;
    teams: AccountTeam[];
}

export interface AccountState {
    account: Account | null;
    accountLoading: boolean;
    apiKey: AccountApiKeyCreateResponse | null;
    apiKeys: AccountApiKey[];
    apiKeysLoading: boolean;
    apiKeyRevoking: boolean;
    invoices: AccountInvoice[];
    invoicesLoading: boolean;
    isApiKeyResultVisible: boolean;
    plans: AccountPlan[];
    plansLoading: boolean;

    currentTeam?: AccountTeam;
}

const initialState: AccountState = {
    account: null,
    accountLoading: false,
    apiKey: null,
    apiKeys: [],
    apiKeysLoading: false,
    apiKeyRevoking: false,
    invoices: [],
    invoicesLoading: true,
    isApiKeyResultVisible: false,
    plans: [],
    plansLoading: false,

    currentTeam: JSON.parse(localStorage.getItem('currentTeam') || 'null')
}

export interface CreateApiKeyPayload {
    name: string;
}

export const clearApiKey = createAction('ClearApiKey');

export const createApiKey = createAsyncThunk<AccountApiKeyCreateResponse, CreateApiKeyPayload>('CreateApiKey', async (payload, thunk) => {
    try {
        const response = await fetchAuthed('/api/account/api-keys', {
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });

        thunk.dispatch(loadApiKeys());

        return response;
    } catch (e) {
        handleError(e, 'creating your API key');
    }
});

export const loadAccount = createAsyncThunk<Account, void, { state: RootState }>('LoadAccount', async (payload, thunk) => {
    try {
        return await fetchAuthed('/api/account');
    } catch (e) {
        // If we get a 401, log out from the team as it's probably something caused by team membership
        if (e.response.status === 401) {
            thunk.dispatch(unsetTeam());
            return;
        }

        handleError(e, 'loading your account');
        return null;
    }
});

export const loadApiKeys = createAsyncThunk<AccountApiKey[], void>('LoadApiKeys', async (payload, thunk) => {
    try {
        return await fetchAuthed('/api/account/api-keys');
    } catch (e) {
        handleError(e, 'loading your API keys');
        return [];
    }
});

export const loadInvoices = createAsyncThunk<AccountInvoice[], void>('LoadAccountInvoices', async (payload, thunk) => {
    try {
        return await fetchAuthed('/api/account/invoices');
    } catch (e) {
        handleError(e, 'loading your invoices');
        return [];
    }
});

export const loadPlans = createAsyncThunk<AccountPlan[], void>('LoadPlans', async (payload, thunk) => {
    try {
        return await fetchAuthed('/api/chargebee/plans');
    } catch (e) {
        handleError(e, 'loading the available plans');
        return [];
    }
});

export const revokeApiKey = createAsyncThunk<void, string>('RevokeApiKey', async (payload, thunk) => {
    try {
        await fetchAuthed('/api/account/api-keys/' + payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        });

        thunk.dispatch(loadApiKeys());
    } catch (e) {
        handleError(e, 'revoking the API key');
    }
});

export const switchTeam = createAsyncThunk<AccountTeam, string, { state: RootState }>('SwitchTeam', async (payload, thunk) => {
    try {
        const team = await fetchAuthed('/api/teams/' + payload);

        // TODO: I'm 99% sure this will be a race condition
        thunk.dispatch(loadAccount());

        // TODO: This is kinda hacky, and it serializes the name too, which might have changed since the user switched
        localStorage.setItem('currentTeam', JSON.stringify(team));

        return team;
    } catch (e) {
        handleError(e, 'loading your team');
        return;
    }
});

export const unsetTeam = createAsyncThunk('UnsetTeam', async (payload, thunk) => {
    localStorage.removeItem('currentTeam');
});

export const toggleApiKeyResultVisible = createAction('ToggleApiKeyResultVisible');

export default createReducer(initialState, builder => builder
    .addCase(clearApiKey, (state) => {
        return {
            ...state,
            apiKey: null
        };
    })
    .addCase(createApiKey.fulfilled, (state, action) => {
        return {
            ...state,
            apiKey: action.payload
        }
    })
    .addCase(loadAccount.pending, (state, action) => {
        return {
            ...state,
            accountLoading: true
        }
    })
    .addCase(loadAccount.fulfilled, (state, action) => {
        return {
            ...state,
            account: action.payload,
            accountLoading: false
        }
    })
    .addCase(loadApiKeys.pending, state => {
        return {
            ...state,
            apiKeysLoading: true
        }
    })
    .addCase(loadApiKeys.fulfilled, (state, action) => {
        return {
            ...state,
            apiKeys: action.payload,
            apiKeysLoading: false
        }
    })
    .addCase(loadInvoices.pending, state => {
        return {
            ...state,
            invoicesLoading: true
        }
    })
    .addCase(loadInvoices.fulfilled, (state, action) => {
        return {
            ...state,
            invoices: action.payload,
            invoicesLoading: false
        }
    })
    .addCase(loadPlans.pending, (state, action) => {
        return {
            ...state,
            plansLoading: true
        }
    })
    .addCase(loadPlans.fulfilled, (state, action) => {
        return {
            ...state,
            plans: action.payload,
            plansLoading: false
        }
    })
    .addCase(revokeApiKey.pending, state => {
        return {
            ...state,
            apiKeyRevoking: true
        }
    })
    .addCase(revokeApiKey.fulfilled, state => {
        return {
            ...state,
            apiKeyRevoking: false
        }
    })
    .addCase(switchTeam.fulfilled, (state, action) => {
        return {
            ...state,
            currentTeam: action.payload
        }
    })
    .addCase(toggleApiKeyResultVisible, state => {
        return {
            ...state,
            isApiKeyResultVisible: !state.isApiKeyResultVisible
        }
    })
    .addCase(unsetTeam.fulfilled, state => {
        return {
            ...state,
            currentTeam: undefined
        }
    })
);
