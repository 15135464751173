import * as React from 'react';
import { IconProps } from './IconProps';

export default function PlusIcon(props: IconProps) {
    return (
        <svg { ...props } fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd" />
        </svg>
    );
}
