import * as React from 'react';
import { useForm } from 'react-form';
import Page from '../common/Page';
import Card from '../common/Card';
import Button from '../common/Button';
import InputField from '../common/InputField';
import { Account, loadAccount, switchTeam } from './accountReducer';
import { connect } from 'react-redux';
import { RootState } from '../store';
import { handleError } from '../utils/errors';
import { fetchAuthed } from '../utils/ajax';

const GameDay = React.lazy(() => import('../images/GameDay'));

function validateName(value: string) {
    if (!value) {
        return 'Please enter a team name';
    }

    return false;
}

function validateEmail(value: string) {
    if (!value) {
        return 'Please enter a team contact email address';
    }

    if (!value.includes('@')) {
        return 'Please enter a valid email address';
    }

    return false;
}

interface Props {
    account: Account | null;

    loadAccount(): void;
    switchTeam(team: string): void;
}

function AccountTeamSetupPage({ account, loadAccount, switchTeam }: Props) {
    const { Form, meta: { isSubmitting, canSubmit } } = useForm({
        onSubmit: async (values: any) => {
            try {
                const response = await fetchAuthed('/api/teams', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });

                // TODO: Select the newly created team as the current context
                await switchTeam(response.id);
            } catch (e) {
                handleError(e, 'creating your team');
            }

            // TODO: Reload account
        }
    });

    return (
        <Page isLoading={ false } breadcrumbs={ [
            {
                href: '/account',
                name: 'Account'
            }
        ] }>
            <Card>
                <Form>
                    <div className="my-4">
                        <div className="flex mb-10 justify-center w-full">
                            <GameDay className="w-64 h-full" />
                        </div>

                        <div>
                            <div className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                                <div className="sm:col-span-2 sm:col-start-3 text-center">
                                    <h3 className="text-xl font-bold">Set up your team</h3>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-3">
                                    <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">
                                        Name <span className="text-red-500">*</span>
                                    </label>

                                    <InputField field="name"
                                                placeholder="Enter a team name"
                                                validate={ validateName } />
                                </div>

                                <div className="sm:col-span-2 sm:col-start-3">
                                    <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                                        Contact email <span className="text-red-500">*</span>
                                    </label>

                                    <InputField field="email"
                                                placeholder="Enter a team contact email"
                                                defaultValue={ account?.email }
                                                validate={ validateEmail } />

                                    <p className="mt-2 text-xs text-gray-500">
                                        All billing and team-related emails will be sent here
                                    </p>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-3 text-center">
                                    <Button className="w-full justify-center" htmlType="submit" disabled={ !canSubmit } loading={ isSubmitting }>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Card>
        </Page>
    );
}

const mapStateToProps = (state: RootState) => ({
    account: state.account.account
});

const mapDispatchToProps = ({
    loadAccount,
    switchTeam
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountTeamSetupPage);
