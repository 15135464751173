import * as React from 'react';
import { format, parseISO } from 'date-fns';
import { AccountInvoice } from './accountReducer';
import Button from '../common/Button';
import DownloadDocumentIcon from '../icons/DownloadDocumentIcon';
import { fetchAuthed } from '../utils/ajax';
import Table from '../common/Table';

interface Props {
    invoices: AccountInvoice[];
}

function AccountBillingContent({ invoices }: Props) {
    const columns = [
        {
            key: 'createdAt',
            name: 'Date',
            width: 'auto',
            render: (row: AccountInvoice) => {
                return (
                    <span>
                        { format(parseISO(row.createdAt), 'do LLLL y, HH:mm') }
                    </span>
                )
            }
        },
        {
            key: 'total',
            name: 'Total',
            width: 'auto',
            render: (row: AccountInvoice) => {
                return (
                    <span>
                        £{ (row.total / 100).toFixed(2) }
                    </span>
                )
            }
        },
        {
            name: 'Download',
            width: 'auto',
            render: (row: AccountInvoice) => {
                const onClickDownload = async () => {
                    const response = await fetchAuthed('/api/account/invoices/' + row.id + '/pdf');

                    let a = document.createElement('a')
                    a.href = response.uri
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                };

                return (
                    <Button onClick={ onClickDownload } size="tiny">
                        <DownloadDocumentIcon className="h-4 w-4 -ml-0.5 mr-2" /> Download
                    </Button>
                )
            }
        }
    ];

    return <Table columns={ columns } data={ invoices } noDataText="No past invoices" />;
}

export default AccountBillingContent;
