import React, { ReactElement, useContext, useEffect, useRef } from 'react'
import { CSSTransition as ReactCSSTransition } from 'react-transition-group'

type TransitionContextProps = {
    parent: {
        show: boolean
        isInitialRender: boolean
        appear?: boolean
    }
}

const TransitionContext = React.createContext<Partial<TransitionContextProps>>({
    parent: {
        show: false,
        isInitialRender: true,
    },
})

function useIsInitialRender(): boolean {
    const isInitialRender = useRef(true)

    useEffect(() => {
        isInitialRender.current = false
    }, [])

    return isInitialRender.current
}

interface TransitionProps {
    show?: boolean
    enter?: string
    enterFrom?: string
    enterTo?: string
    leave?: string
    leaveFrom?: string
    leaveTo?: string
    appear?: boolean
    children: React.ReactNode
}

type CSSTransitionProps = TransitionProps

function CSSTransition({
                           show,
                           enter = '',
                           enterFrom = '',
                           enterTo = '',
                           leave = '',
                           leaveFrom = '',
                           leaveTo = '',
                           appear,
                           children,
                       }: CSSTransitionProps): ReactElement {
    const enterClasses = enter.split(' ').filter((s) => s.length)
    const enterFromClasses = enterFrom.split(' ').filter((s) => s.length)
    const enterToClasses = enterTo.split(' ').filter((s) => s.length)
    const leaveClasses = leave.split(' ').filter((s) => s.length)
    const leaveFromClasses = leaveFrom.split(' ').filter((s) => s.length)
    const leaveToClasses = leaveTo.split(' ').filter((s) => s.length)

    function addClasses(node: HTMLElement, classes: string[]): void {
        if (classes.length) {
            node.classList.add(...classes)
        }
    }

    function removeClasses(node: HTMLElement, classes: string[]): void {
        if (classes.length) {
            node.classList.remove(...classes)
        }
    }

    const addEndListener = (node: HTMLElement, done: () => void): void => {
        node?.addEventListener('transitionend', done, false)
    }

    const onEnter = (node: HTMLElement): void => {
        if (node) addClasses(node, [...enterClasses, ...enterFromClasses])
    }

    const onEntering = (node: HTMLElement): void => {
        if (node) {
            removeClasses(node, enterFromClasses)
            addClasses(node, enterToClasses)
        }
    }

    const onEntered = (node: HTMLElement): void => {
        if (node) removeClasses(node, [...enterToClasses, ...enterClasses])
    }

    const onExit = (node: HTMLElement): void => {
        if (node) addClasses(node, [...leaveClasses, ...leaveFromClasses])
    }

    const onExiting = (node: HTMLElement): void => {
        if (node) {
            removeClasses(node, leaveFromClasses)
            addClasses(node, leaveToClasses)
        }
    }

    const onExited = (node: HTMLElement): void => {
        if (node) removeClasses(node, [...leaveToClasses, ...leaveClasses])
    }

    return (
        <ReactCSSTransition
            appear={appear}
            unmountOnExit
            in={show}
            addEndListener={addEndListener}
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
        >
            {children}
        </ReactCSSTransition>
    )
}

function Transition({ show, appear, ...rest }: TransitionProps): ReactElement {
    const { parent } = useContext(TransitionContext)
    const isInitialRender = useIsInitialRender()
    const isChild = show === undefined

    if (isChild) {
        return (
            <CSSTransition
                appear={parent ? parent.appear || !parent.isInitialRender : false}
                show={parent?.show ? parent.show : false}
                {...rest}
            />
        )
    }

    return (
        <TransitionContext.Provider
            value={{
                parent: {
                    show: Boolean(show),
                    isInitialRender,
                    appear,
                },
            }}
        >
            <CSSTransition appear={appear} show={show} {...rest} />
        </TransitionContext.Provider>
    )
}

export default Transition;
