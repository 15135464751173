import * as React from 'react';
import classNames from 'classcat';
import useTable from './useTable';
import { isEven, isOdd } from '../utils/numbers';

interface Props {
    columns: any;
    data: any;
    noDataText?: string;
}

function Table({ columns, data, noDataText = 'No data' }: Props) {
    const table = useTable({ columns, data });

    // Render the table header
    const headers = table.headers.map(header => {
        return (
            <th { ...header.getHeaderProps() } className="display-flex items-center px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                { header.render() }
            </th>
        );
    });

    // Render the table body
    let rows;
    if (table.rows.length) {
        rows = table.rows.map(row => {
            const cells = row.cells.map(cell => (
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    { cell.render() }
                </td>
            ));

            const classes = classNames({
                'bg-white': isEven(row.index),
                'bg-gray-50': isOdd(row.index)
            });

            return (
                <tr className={ classes }>
                    { cells }
                </tr>
            );
        });
    } else {
        rows = (
            <tr>
                <td colSpan={ columns.length } className="pt-6 text-center text-gray-600 text-sm">
                    { noDataText }
                </td>
            </tr>
        );
    }

    return (
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table className="min-w-full">
                <thead>
                <tr>
                    { headers }
                </tr>
                </thead>

                <tbody className="bg-white">
                { rows }
                </tbody>
            </table>
        </div>
    );
}

export default Table;
