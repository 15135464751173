import * as React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';
import {
    AccountApiKeyCreateResponse,
    clearApiKey,
    createApiKey,
    CreateApiKeyPayload
} from './accountReducer';
import { RootState } from '../store';
import AccountApiKeysCreateForm from './AccountApiKeysCreateForm';
import AccountApiKeysCreateResult from './AccountApiKeysCreateResult';

interface Props {
    apiKey: AccountApiKeyCreateResponse | null;

    clearApiKey(): void;
    onCreate(payload: CreateApiKeyPayload): void;
}

class AccountApiKeysCreatePage extends React.Component<Props> {
    componentWillUnmount() {
        this.props.clearApiKey();
    }

    render() {
        let { apiKey, onCreate } = this.props;

        const pageContent = apiKey === null
            ? <AccountApiKeysCreateForm onCreate={ onCreate } />
            : <AccountApiKeysCreateResult apiKey={ apiKey } />;

        return (
            <>
                <PageHeader breadcrumbs={[
                    {
                        href: '/account/api-keys',
                        name: 'API Keys'
                    },
                    {
                        href: '/account/api-keys/new',
                        name: 'New'
                    }
                ]} />

                <PageContent isLoading={ false }>
                    { pageContent }
                </PageContent>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    apiKey: state.account.apiKey,
});

const mapDispatchToProps = ({
    clearApiKey,
    onCreate: createApiKey,
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountApiKeysCreatePage);
