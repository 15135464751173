import * as React from 'react';
import Loadable from './Loadable';

interface Props {
    children: React.ReactNode;
    isLoading: boolean;
}

function PageContent({ children, isLoading }: Props) {
    return (
        <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <Loadable isLoading={ isLoading }>
                    { children }
                </Loadable>
            </div>
        </main>
    );
}

export default PageContent;
