import React, { useState } from 'react';
import { connect } from 'react-redux';
import randomColor from 'randomcolor';
import Page from '../common/Page';
import { RootState } from '../store';
import { AccountTeam, switchTeam } from './accountReducer';

function generateInitials(id: string, name: string) {
    const initials = name.split(' ')
        .map(i => i[0])
        .slice(0, 2)
        .join('');

    const color = randomColor({ seed: name, luminosity: 'light' });

    return (
        <div style={ { background: color } }
             className="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm leading-5 font-medium rounded-l-md text-gray-900">
            { initials.toUpperCase() }
        </div>
    )
}

interface Props {
    teams: AccountTeam[];

    switchTeam(team: string): void;
}

function AccountTeamPickerPage({ switchTeam, teams }: Props) {
    const [search, setSearch] = useState<string>('');

    const list = teams
        .filter(team => team.name.toLowerCase().includes(search?.toLowerCase()))
        .map(team => (
            <li key={ team.id } className="col-span-1 flex shadow-sm rounded-md">
                { generateInitials(team.id, team.name) }

                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 text-sm leading-5 truncate w-full justify-start">
                        <button onClick={ () => switchTeam(team.id) }
                                className="text-gray-900 text-left font-medium hover:text-gray-600 transition ease-in-out duration-150 w-full h-12">
                            { team.name }
                        </button>
                    </div>

                    <div className="flex-shrink-0 pr-2">
                        <button onClick={ () => switchTeam(team.id) }
                                className="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </li>
        ));

    return (
        <Page breadcrumbs={ [] } isLoading={ false }>
            <div className="flex flex-col items-center">
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 lg:grid-cols-1 w-2/5">
                    <li className="col-span-1 flex shadow-sm rounded-md">
                        <input className="form-input block w-full sm:text-sm sm:leading-5"
                               onChange={ e => setSearch(e.currentTarget.value) }
                               placeholder="Search for a team" />
                    </li>

                    { list }
                </ul>
            </div>
        </Page>
    );
}

const mapStateToProps = (state: RootState) => ({
    teams: (state.account.account?.teams || [])
});

const mapDispatchToProps = ({
    switchTeam
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountTeamPickerPage);
