import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AuthState {
    loading: boolean;
    user: any;
}

const initialState: AuthState = {
    loading: false,
    user: null
};

export const login = createAction<any>('Login');

export const logout = createAction('Logout');

export const toggleAuthLoading = createAction('ToggleAuthLoading');

export default createReducer(initialState, builder => builder
    .addCase(login, (state, action) => {
        return {
            ...state,
            user: action.payload
        }
    })
    .addCase(logout, (state, action) => {
        return {
            ...state,
            user: null
        }
    })
    .addCase(toggleAuthLoading, (state) => {
        return {
            ...state,
            loading: !state.loading
        }
    })
);
