import { createAction, createReducer } from '@reduxjs/toolkit';

interface SendNotificationPayload {
    duration: number | null;
    message: string | string[];
    title: string;
    type: 'error' | 'info' | 'success' | 'warning';
}

export type Notification = SendNotificationPayload & {
    id: string;
}

export type NotificationsState = Notification[];

const initialState: Notification[] = [];

export const hideNotification = createAction<string>('HideNotification');

export const removeAllNotifications = createAction('RemoveAllNotifications');

export const sendNotification = createAction<SendNotificationPayload>('SendNotification');

export default createReducer(initialState, builder => builder
    .addCase(hideNotification, (state, action) => {
        return state.filter(notification => {
            return notification.id !== action.payload
        });
    })
    .addCase(removeAllNotifications, () => {
        return initialState;
    })
    .addCase(sendNotification, (state, action) => {
        return [
            ...state,
            {
                ...action.payload,
                id: Math.random().toString(36).substring(7)
            }
        ]
    })
);
