import React from 'react';
import { useNavigation, View } from 'react-navi';
import './App.less';
import AppAuthBoundary from './AppAuthBoundary';
import AppHeader from './AppHeader';
import AppErrorBoundary from './AppErrorBoundary';

function App() {
    const navigation = useNavigation();

    return (
        <AppAuthBoundary>
            <AppHeader />

            <AppErrorBoundary navigation={ navigation }>
                <View />
            </AppErrorBoundary>
        </AppAuthBoundary>
    );
}

export default App;
