import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../store';
import { Account, AccountPlan, loadAccount, loadPlans } from './accountReducer';
import AccountTeamPlansContent from './AccountTeamPlansContent';
import Page from '../common/Page';

interface Props {
    account: Account | null;
    isLoading: boolean;
    plans: AccountPlan[];

    loadAccount(): void;
    loadPlans(): void;
}

class AccountTeamPlansPage extends React.Component<Props> {
    componentDidMount() {
        this.props.loadPlans();
    }

    render() {
        const { account, isLoading, loadAccount, plans } = this.props;

        if (account === null) {
            // TODO: This should never happen
            return null;
        }

        return (
            <Page isLoading={ isLoading } breadcrumbs={[
                    {
                        href: '/account',
                        name: 'Account'
                    },
                ]}>
                <AccountTeamPlansContent loadAccount={ loadAccount } plans={ plans } />
            </Page>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    account: state.account.account,
    isLoading: state.account.accountLoading || state.account.plansLoading,
    plans: state.account.plans,
});

const mapDispatchToProps = ({
    loadAccount,
    loadPlans
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountTeamPlansPage);
