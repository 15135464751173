import * as React from 'react';
import { IconProps } from './IconProps';

export default function InfoIcon(props: IconProps) {
    return (
        <svg { ...props } fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
             stroke="currentColor">
            <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    );
}
