import * as React from 'react';
import { useField, useForm } from 'react-form';
import { CreateApiKeyPayload } from './accountReducer';
import Button from '../common/Button';
import Card from '../common/Card';

function NameField() {
    const { meta: { error, isTouched, isValidating }, getInputProps } = useField("name", {
        defaultValue: '',
        validatePristine: true,
        validate: function validateName(name: string) {
            if (!name || name === '') {
                return 'Please enter a name for your API key';
            }

            return false;
        }
    });

    return (
        <>
            <div className="mt-1 flex rounded-md shadow-sm">
                <input {...getInputProps()} className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
            </div>

            {isValidating ? (
                <em>Validating...</em>
            ) : isTouched && error ? (
                <p className="mt-2 text-red-500 text-xs">{error}</p>
            ) : null}
        </>
    );
}

function DescriptionField() {
    const { fieldName, meta: { error, isTouched, isValidating }, value, setValue } = useField("description");

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.currentTarget.value);
    }

    return (
        <>
            <div className="mt-1 flex rounded-md shadow-sm">
                <textarea id={ fieldName } onChange={ onChange } rows={ 3 } className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                    { value }
                </textarea>
            </div>

            {isValidating ? (
                <em>Validating...</em>
            ) : isTouched && error ? (
                <p className="mt-2 text-red-500 text-xs">{error}</p>
            ) : null}
        </>
    );
}

interface Props {
    onCreate(payload: CreateApiKeyPayload): void;
}

function AccountApiKeysCreateForm({ onCreate }: Props) {
    const { Form, meta: { isSubmitting, canSubmit } } = useForm({
        onSubmit: async (values: CreateApiKeyPayload) => {
            await onCreate(values);
        }
    });

    return (
        <Form>
            <Card>
                <div>
                    <div>
                        <div className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="name"
                                       className="block text-sm font-medium leading-5 text-gray-700">
                                    Name <span className="text-red-500">*</span>
                                </label>

                                <NameField />
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="about"
                                       className="block text-sm font-medium leading-5 text-gray-700">
                                    Description
                                </label>

                                <DescriptionField />

                                <p className="mt-2 text-sm text-gray-500">Give a short description of what the key
                                    will be used for.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 border-t border-gray-200 pt-5">
                    <div className="flex justify-end">
                        <Button htmlType="submit" disabled={ !canSubmit } loading={ isSubmitting }>
                            Create
                        </Button>
                    </div>
                </div>
            </Card>
        </Form>
    )
}

export default AccountApiKeysCreateForm;
