import * as React from 'react';
import classNames from 'classcat';

interface Props {
    children: React.ReactNode;
    color?: string;
}

function Badge({ children, color = 'bg-gray-100' }: Props) {
    const classes = classNames({
        'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-gray-100 text-gray-800': true,
        [color]: true
    })

    return (
        <span className={ classes }>
            { children }
        </span>
    );
}

export default Badge;
