import * as React from 'react';
import { map, Matcher, mount, redirect, route } from 'navi';
import AccountApiKeysPage from './account/AccountApiKeysPage';
import AccountApiKeysCreatePage from './account/AccountApiKeysCreatePage';
import AccountTeamPlansPage from './account/AccountTeamPlansPage';
import AccountBillingPage from './account/AccountBillingPage';
import AccountPage from './account/AccountPage';
import ClustersPage from './clusters/ClustersPage';
import ClusterCreatePage from './clusters/ClusterCreatePage';
import ClusterViewPage from './clusters/ClusterViewPage';
import AccountTeamSetupPage from './account/AccountTeamSetupPage';
import AccountTeamPickerPage from './account/AccountTeamPickerPage';
import { Account, AccountTeam } from './account/accountReducer';
import TeamPage from './team/TeamPage';
import TeamInvitePage from './team/TeamInvitePage';

interface Context {
    account: Account;
    currentTeam: AccountTeam;
}

function routeWithTeamAuthentication(matcher: Matcher<any>) {
    return map<any>((request, context: Context) => {
        if (context.account && context.account.teams) {
            // If the user doesn't belong to any teams, prompt them to create one
            if (context.account.teams.length === 0) {
                return route({
                    title: 'Setup Team',
                    view: <AccountTeamSetupPage />
                });
            }

            // If their team is subscribed, take them where they're asking, otherwise show them the setup page
            let team = context.account.teams.find((t: any) => t.id === context.currentTeam?.id);
            if (team) {
                if (team.isSubscribed) {
                    return matcher;
                } else {
                    return route({
                        title: 'Team Plan',
                        view: <AccountTeamPlansPage />
                    });
                }
            }

            // Otherwise, show them the team picker
            return route({
                title: 'Choose Team',
                view: <AccountTeamPickerPage />
            });
        }

        return route({
            view: <div />
        });
    });
}

export const routes = mount({
    '/': redirect('/clusters'),
    '/account/api-keys': routeWithTeamAuthentication(route({
        title: 'API Keys',
        view: <AccountApiKeysPage />
    })),
    '/account/api-keys/new': routeWithTeamAuthentication(route({
        title: 'New API Key',
        view: <AccountApiKeysCreatePage />
    })),
    '/account/billing': routeWithTeamAuthentication(route({
        title: 'Account Billing',
        view: <AccountBillingPage />
    })),
    '/account': route({
        title: 'Account',
        view: <AccountPage />
    }),
    '/clusters': routeWithTeamAuthentication(route({
        data: {
            key: 'clusters'
        },
        title: 'Clusters',
        view: <ClustersPage />
    })),
    '/clusters/new': routeWithTeamAuthentication(route({
        title: 'New Cluster',
        view: <ClusterCreatePage />
    })),
    '/clusters/:id': routeWithTeamAuthentication(route({
        title: 'Cluster',
        getView: (request) => {
            return <ClusterViewPage id={ request.params.id } />
        }
    })),
    '/team': routeWithTeamAuthentication(route({
        title: 'Team',
        view: <TeamPage />
    })),
    '/team/invite': routeWithTeamAuthentication(route({
        title: 'Invite',
        view: <TeamInvitePage />
    })),
});
