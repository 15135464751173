import * as React from 'react';
import { ErrorInfo } from 'react';
import PageContent from './common/PageContent';
import Card from './common/Card';
import { useNavigation } from 'react-navi';
import { Navigation } from 'navi';
import { SimpleSubscription } from 'navi/dist/types/Observable';
import ErrorIcon from './icons/ErrorIcon';

interface Props {
    navigation: Navigation;
}

interface State {
    error: Error | null;
}

export default class AppErrorBoundary extends React.Component<Props, State> {
    subscriber?: SimpleSubscription;

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidMount() {
        // Whenever the location changes, wipe any existing error
        this.subscriber = this.props.navigation.subscribe(route => {
            if (this.state.error) {
                this.setState({
                    error: null
                });
            }
        })
    }

    componentWillUnmount() {
        this.subscriber?.unsubscribe();
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.error(error, errorInfo)
    }

    render() {
        const navigation = useNavigation();

        if (this.state.error) {
            return (
                <PageContent isLoading={ false }>
                    <Card>
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ErrorIcon className="h-5 w-5 text-red-400" />
                                </div>

                                <div className="ml-3">
                                    <h3 className="text-sm leading-5 font-medium text-red-800">
                                        Something went wrong.
                                    </h3>
                                    <div className="mt-2 text-sm leading-5 text-red-700">
                                        { this.state.error.name }: { this.state.error.message }
                                    </div>
                                    <div className="mt-4">
                                        <div className="-mx-2 -my-1.5 flex">
                                            <button
                                                className="px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:bg-red-100 transition ease-in-out duration-150"
                                                onClick={ async () => await navigation.goBack() }
                                            >
                                                Go back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </PageContent>
            );
        }

        return this.props.children;
    }
}
