import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from './store';
import Transition from './common/Transition';
import { hideNotification, Notification } from './notifications/notificationReducer';
import ErrorIcon from './icons/ErrorIcon';
import WarningIcon from './icons/WarningIcon';
import SuccessIcon from './icons/SuccessIcon';
import InfoIcon from './icons/InfoIcon';

interface Props {
    notifications: Notification[];

    hideNotification(id: string): void;
}

function AppNotification({ notification, onClose }: { notification: Notification, onClose(): void }) {
    const [visible, setVisible] = useState(true);

    const onCloseNotification = () => {
        setVisible(false);

        setTimeout(() => onClose(), 2000);
    }

    if (notification.duration) {
        setTimeout(onCloseNotification, notification.duration);
    }

    let icon;
    switch (notification.type) {
        case 'error':
            icon = (
                <ErrorIcon className="h-6 w-6 text-red-400" />
            );
            break;
        case 'warning':
            icon = (
                <WarningIcon className="h-6 w-6 text-yellow-400" />
            );
            break;
        case 'success':
            icon = (
                <SuccessIcon className="h-6 w-6 text-green-400" />
            );
            break;
        default:
            icon = (
                <InfoIcon className="h-6 w-6 text-blue-400" />
            );
            break;
    }

    const messages = notification.message instanceof Array
        ? notification.message.map(m => <p className="mt-2 text-sm leading-5 text-gray-500">{ m }</p>)
        : <p className="mt-2 text-sm leading-5 text-gray-500">{ notification.message }</p>;

    return (
        <Transition
            show={ visible }
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="ml-auto mb-5 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
                <div className="rounded-lg shadow-xs overflow-hidden">
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                { icon }
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm leading-5 font-medium text-gray-900">
                                    { notification.title }
                                </p>

                                { messages }
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    onClick={ onCloseNotification }
                                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                              clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}

function AppNotifications(props: Props) {
    const notifications = props.notifications.map(notification => {
        return <AppNotification key={ notification.id } notification={ notification }
                                onClose={ () => props.hideNotification(notification.id) } />
    });

    return (
        <div
            className="fixed z-40 inset-0 flex flex-col items-start justify-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-start">
            { notifications }
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    notifications: state.notifications
});

const mapDispatchToProps = ({
    hideNotification,
})

export default connect(mapStateToProps, mapDispatchToProps)(AppNotifications);
