import * as React from 'react';
import classNames from 'classcat';

interface Props {
    className?: string;
    children: React.ReactNode;
    disabled?: boolean;
    htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    loading?: boolean;
    size?: 'default' | 'small' | 'tiny';
    type?: 'primary' | 'secondary' | 'danger';

    onClick?(): void;
}

function Button({ className = '', children, disabled = false, htmlType = 'button', loading = false, onClick, size = 'default', type = 'primary' }: Props) {
    const classes = classNames({
        [className]: true,
        'px-4 py-2 leading-5 text-sm': size === 'default',
        'px-3 py-2 leading-4 text-sm': size === 'small',
        'px-2.5 py-1.5 leading-4 text-xs': size === 'tiny',
        'inline-flex items-center border border-transparent font-medium rounded-md text-white focus:outline-none transition duration-150 ease-in-out': true,
        'bg-gray-600 hover:bg-gray-500 focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700': type === 'secondary',
        'bg-blue-500 hover:bg-blue-400 focus:shadow-outline-blue focus:border-blue-600 active:bg-blue-600': type === 'primary',
        'bg-red-500 hover:bg-red-400 focus:shadow-outline-red focus:border-red-600 active:bg-red-600': type === 'danger',
        'opacity-50 cursor-not-allowed': disabled
    });

    const content = loading
        ? <svg className="h-5 spinning" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
        : children;

    return (
        <span className="shadow-sm rounded-md">
            <button disabled={ disabled } onClick={ onClick } type={ htmlType } className={ classes }>
                { content }
            </button>
        </span>
    );
}

export default Button;
