import * as React from 'react';
import Button from '../common/Button';
import { AccountPlan } from './accountReducer';

interface PlanPoint {
    id: string;
    points: string[];
}

const planPoints: PlanPoint[] = [
    {
        id: 'free',
        points: [
            'Basic support',
        ]
    },
    {
        id: 'tier-1',
        points: [
            'Basic support',
        ]
    },
    {
        id: 'tier-2',
        points: [
            'Priority support',
        ]
    },
    {
        id: 'tier-3',
        points: [
            'Priority support',
        ]
    }
];

interface Props {
    plans: AccountPlan[];

    onClick(plan: string): void;
}

function Plans({ onClick, plans }: Props) {
    const tiers = plans.map(plan => {
        const planPoint = planPoints.find(p => p.id === plan.id);

        return (
            <div className="bg-gray-50 overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6 bg-white">
                    <h3 className="text-lg font-medium">
                        { plan.name }
                    </h3>

                    <div>
                        <span className="text-3xl font-semibold mr-2">
                            £{ (plan.cost / 100) }
                        </span>

                        <span className="text-xl text-gray-600">
                            / month
                        </span>
                    </div>
                </div>

                <div className="border-t-2 border-gray-100 py-4 text-gray-700 leading-relaxed text-sm">
                    <ul>
                        <li>
                            <strong className="font-semibold">{ plan.credits }</strong> credits
                        </li>

                        {
                            planPoint?.points.map(point => (
                                <li>{ point }</li>
                            ))
                        }
                    </ul>
                </div>

                <div className="pb-5">
                    <Button onClick={ () => onClick(plan.id) }>
                        Select
                    </Button>
                </div>
            </div>
        );
    });

    return (
        <div className="grid grid-flow-row grid-cols-2 grid-rows-2 md:grid-cols-4 md:grid-rows-1 gap-4 text-center mt-3">
            { tiers }
        </div>
    );
}

export default Plans;
