import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-navi';
import { RootState } from './store';
import { ReactComponent as Logo } from './logo.svg';
import auth from './auth';
import NavigationItem from './common/NavigationItem';
import DropdownItem from './common/DropdownItem';
import Transition from './common/Transition';
import classNames from 'classcat';
import { AccountTeam, unsetTeam } from './account/accountReducer';

interface NavigationItem {
    href: string;
    name: string;

    onClick?(): void;
}

interface Props {
    team?: AccountTeam;
    user: any;

    unsetTeam(): void;
}

function AppHeader({ team, unsetTeam, user }: Props) {
    const [userOpen, setUserOpen] = useState(false);
    const dropdownRef = useRef<HTMLButtonElement>(null);

    // Mobile stuff
    const [menuOpen, setMenuOpen] = useState(false);

    // If the user clicks outside of the user dropdown, we need to close it
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setUserOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);

    const userMenuItems = [
        {
            href: '/account',
            name: 'My account'
        }
    ]

    let navigationMenuItems: NavigationItem[] = [];
    let navigationTeamItems: NavigationItem[] = [];
    let navigationTeam;
    if (team) {
        navigationMenuItems = [
            {
                href: '/clusters',
                name: 'Clusters'
            },
            {
                href: '/account/api-keys',
                name: 'API Keys'
            },
            {
                href: '/team',
                name: 'Team'
            },
        ];

        navigationTeamItems = [

            {
                href: '/clusters',
                name: 'Switch team',
                onClick: () => unsetTeam()
            }
        ]

        navigationTeam = (
            <>
                <div className="px-4 py-3">
                    <p className="text-sm leading-5">
                        Signed into
                    </p>
                    <p className="text-sm leading-5 font-medium text-gray-900 truncate">
                        { team.name }
                    </p>
                </div>

                <div className="border-t border-gray-100" />

                {
                    navigationTeamItems.map(item => (
                        <Link className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href={ item.href } onClick={ item.onClick }>
                            { item.name }
                        </Link>
                    ))
                }

                <div className="border-t border-gray-100" />
            </>
        );
    }

    const mobileButtonOpenClasses = classNames({
        'h-6 w-6': true,
        'block': !menuOpen,
        'hidden': menuOpen
    });

    const mobileButtonCloseClasses = classNames({
        'h-6 w-6': true,
        'block': menuOpen,
        'hidden': !menuOpen
    });

    const mobileMenuClasses = classNames({
        'md:hidden': true,
        'hidden': !menuOpen,
        'block': menuOpen
    });

    const onClickLogout = () => {
        unsetTeam();
        auth.signOut();
    };

    return (
        <nav className="bg-gray-700">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="border-b border-gray-700">
                    <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <Link href="/clusters">
                                    <Logo className="h-5 w-36" fill="#2081c5" />
                                </Link>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline">
                                    {
                                        navigationMenuItems.map(item => (
                                            <NavigationItem href={ item.href }>
                                                { item.name }
                                            </NavigationItem>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                                <div className="ml-3 relative">
                                    <div>
                                        <button
                                            ref={ dropdownRef }
                                            className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                                            id="user-menu" aria-label="User menu" aria-haspopup="true"
                                            onClick={ () => setUserOpen(!userOpen) }
                                        >
                                            <img className="h-8 w-8 rounded-full"
                                                 src={ user.photoUrl }
                                                 alt={ user.displayName } />
                                        </button>
                                    </div>

                                    <Transition
                                        show={ userOpen }
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                                            <div className="py-1 rounded-md bg-white shadow-xs">
                                                { navigationTeam }

                                                {
                                                    userMenuItems.map(item => (
                                                        <DropdownItem href={ item.href }>
                                                            { item.name }
                                                        </DropdownItem>
                                                    ))
                                                }

                                                <DropdownItem onClick={ onClickLogout }>
                                                    <span className="text-red-600">Logout</span>
                                                </DropdownItem>
                                            </div>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </div>

                        <div className="-mr-2 flex md:hidden">
                            <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white" onClick={ () => setMenuOpen(!menuOpen) }>
                                <svg className={ mobileButtonOpenClasses } stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M4 6h16M4 12h16M4 18h16" />
                                </svg>

                                <svg className={ mobileButtonCloseClasses } stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={ mobileMenuClasses }>
                <div className="px-2 pt-2 pb-3 sm:px-3">
                    {
                        navigationMenuItems.map(item => (
                            <Link key={ item.href } href={ item.href } className="first:mt-0 mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                { item.name }
                            </Link>
                        ))
                    }
                </div>
                <div className="pt-4 pb-3 border-t border-gray-600">
                    <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                            <img className="h-10 w-10 rounded-full"
                                 src={ user.photoUrl }
                                 alt={ user.displayName } />
                        </div>
                        <div className="ml-3">
                            <div className="text-base font-medium leading-none text-white">{ user.displayName }</div>
                            <div className="mt-1 text-sm font-medium leading-none text-gray-400">{ user.email }</div>
                        </div>
                    </div>
                    <div className="mt-3 px-2">
                        {
                            userMenuItems.map(item => (
                                <Link href={ item.href } className="first:mt-0 mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                    { item.name }
                                </Link>
                            ))
                        }

                        <button className="first:mt-0 mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700" onClick={ () => auth.signOut() }>
                            <span className="text-red-600">Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

const mapStateToProps = (state: RootState) => ({
    team: state.account.currentTeam,
    user: state.auth.user
});

const mapDispatchToProps = ({
    unsetTeam
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
