import * as React from 'react';
import { ReactComponent as LoadingBars } from 'loading-svg/loading-bars.svg';

interface Props {
    color?: string;
}

function Loader({ color = '#252f3f' }: Props) {
    return (
        <LoadingBars height={ 48 } width={ 48 } type="bars" fill={ color } />
    );
}

export default Loader;
