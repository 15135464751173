import * as React from 'react';
import { splitFormProps, useField, useForm } from 'react-form';
import { useNavigation } from 'react-navi';
import classNames from 'classcat';
import useSWR, { mutate } from 'swr';
import { CreateClusterPayload } from './clusterReducer';
import { fetchAuthed, fetcher } from '../utils/ajax';
import Button from '../common/Button';
import Loader from '../common/Loader';
import Card from '../common/Card';
import { handleError } from '../utils/errors';

function NameField() {
    const { meta: { error, isTouched, isValidating }, getInputProps } = useField('name', {
        defaultValue: '',
        validatePristine: true,
        validate: function validateName(name: string) {
            if (!name || name === '') {
                return 'Please enter a name for your cluster';
            }

            return false;
        }
    });

    return (
        <>
            <div className="mt-1 flex rounded-md shadow-sm">
                <input { ...getInputProps() }
                       className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
            </div>

            { isValidating ? (
                <em>Validating...</em>
            ) : isTouched && error ? (
                <p className="mt-2 text-red-500 text-xs">{ error }</p>
            ) : null }
        </>
    );
}

function DurationField() {
    const { meta: { error, isTouched, isValidating }, getInputProps } = useField<number>('duration', {
        defaultValue: 6,
        validatePristine: true,
        validate: function validateDuration(duration: number) {
            if (!duration || duration === 0) {
                return 'Please choose how long your cluster should last for';
            }

            if (duration < 1) {
                return 'Clusters must last for at least one hour';
            }

            if (duration > 24) {
                return 'Clusters cannot last longer than 24 hours';
            }

            return false;
        }
    });

    return (
        <>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input type="number" { ...getInputProps() }
                       className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />

                <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm sm:leading-5" id="price-currency">
                        hour(s)
                    </span>
                </div>
            </div>

            { isValidating ? (
                <em>Validating...</em>
            ) : isTouched && error ? (
                <p className="mt-2 text-red-500 text-xs">{ error }</p>
            ) : null }
        </>
    );
}

interface RadioFieldProps {
    field: string;
    options: { disabled: boolean, name: string, description: string, value: string }[];
}

function RadioField(props: RadioFieldProps) {
    const [field, , { options }] = splitFormProps(props);

    const { value = '', setValue, meta: { error, isTouched } } = useField(field, {
        defaultValue: 'sm',
        validatePristine: true,
        validate: function validateType(type: string) {
            if (!type || type === '') {
                return 'Please choose a cluster type';
            }

            return false;
        }
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <>
            <div className="mt-4">
                { options.map(option => {
                    const labelClasses = classNames({
                        'block text-sm leading-5 font-medium': true,
                        'text-gray-700': !option.disabled,
                        'text-gray-300': option.disabled
                    });

                    const descriptionClasses = classNames({
                        'text-gray-500': !option.disabled,
                        'text-gray-300': option.disabled
                    });

                    return (
                        <div key={ option.value } className="mt-4 flex items-center">
                            <input checked={ option.value === value } disabled={ option.disabled } id={ option.value }
                                   name={ `form-input ${ field }` } onChange={ onChange } value={ option.value }
                                   type="radio"
                                   className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out" />

                            <div className="pl-7 text-sm leading-5">
                                <label htmlFor={ option.value }>
                                    <span className={ labelClasses }>{ option.name }</span>
                                </label>

                                <p className={ descriptionClasses }>{ option.description }</p>
                            </div>
                        </div>
                    );
                }) }
            </div>

            { isTouched && error ? <em>{ error }</em> : null }
        </>
    );
}

interface Props {
    onCreate(): void;
}

function ClusterCreateForm({ onCreate }: Props) {
    const navigation = useNavigation();

    const { Form, meta: { isSubmitting, canSubmit } } = useForm({
        onSubmit: async (values: CreateClusterPayload) => {
            try {
                await fetchAuthed('/api/clusters', {
                    method: 'POST',
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                // Make sure SWR reloads the cluster list
                await mutate('/api/clusters');
                await navigation.navigate('/clusters');
                await onCreate();
            } catch (e) {
                handleError(e, 'creating your cluster');
            }
        }
    });

    const { data: clusterTypeData } = useSWR('/api/cluster-types', async url => fetcher(url), {
        suspense: false
    });

    if (!clusterTypeData) {
        return (
            <div className="flex w-full my-16 items-center justify-center">
                <Loader color="#252f3f" />
            </div>
        )
    }

    const clusterTypes = clusterTypeData.map((clusterType: any) => {
        return {
            disabled: clusterType.status !== 'active',
            name: <code>{ clusterType.name }</code>,
            description: `£${clusterType.price} per hour with ${ clusterType.description }`,
            value: clusterType.id
        }
    });

    return (
        <Form>
            <Card>
                <div>
                    <div>
                        <div className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="name"
                                       className="block text-sm font-medium leading-5 text-gray-700">
                                    Name <span className="text-red-500">*</span>
                                </label>

                                <NameField />
                            </div>

                            <div className="sm:col-span-1">
                                <label htmlFor="duration"
                                       className="block text-sm font-medium leading-5 text-gray-700">
                                    Duration <span className="text-red-500">*</span>
                                </label>

                                <DurationField />
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="type"
                                       className="block text-sm font-medium leading-5 text-gray-700">
                                    Type <span className="text-red-500">*</span>
                                </label>

                                <RadioField field="type" options={ clusterTypes } />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 border-t border-gray-200 pt-5">
                    <div className="flex justify-end">
                        <Button className="mr-3" type="secondary" onClick={ async () => await navigation.navigate('/clusters') }>
                            Cancel
                        </Button>
                        <Button htmlType="submit" disabled={ !canSubmit } loading={ isSubmitting }>
                            Create
                        </Button>
                    </div>
                </div>
            </Card>
        </Form>
    );
}

export default ClusterCreateForm;
