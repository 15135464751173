import * as React from 'react';
import { Link } from 'react-navi';
import { connect } from 'react-redux';
import useSWR from 'swr';
import ClustersPageContent from './ClustersPageContent';
import { fetcher } from '../utils/ajax';
import { RootState } from '../store';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';
import Result from '../common/Result';
import Button from '../common/Button';
import SadFaceIcon from '../icons/SadFaceIcon';

function ClustersPage() {
    const { data } = useSWR('/api/clusters', async url => fetcher(url), {
        refreshInterval: 10_000,
        refreshWhenHidden: false,
        refreshWhenOffline: false,
        suspense: false
    });

    let content;
    if (data && data.length) {
        content = <ClustersPageContent clusters={ data } loading={ false } />
    } else {
        content = (
            <Result
                icon={ <SadFaceIcon /> }
                title="No clusters yet!"
                subtitle="Try creating a new cluster using the button below, and go from 0-Kubernetes in 60 seconds."
                extra={
                    <div className="mt-4">
                        <Link href="/clusters/new">
                            <Button>
                                Create a Cluster
                            </Button>
                        </Link>
                    </div>
                }
            />
        )
    }

    return (
        <>
            <PageHeader breadcrumbs={[
                {
                    href: '/clusters',
                    name: 'Clusters'
                },
            ]} extra={[
                <Link key="new" href="/clusters/new">
                    <Button>
                        <svg className="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>

                        New Cluster
                    </Button>
                </Link>
            ]} />

            <PageContent isLoading={ !data }>
                { content }
            </PageContent>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ClustersPage);

