import * as React from 'react';
import { connect } from 'react-redux';
import useSWR from 'swr';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';
import { fetcher } from '../utils/ajax';
import { sendNotification } from '../notifications/notificationReducer';
import ClusterViewPageContent from './ClusterViewPageContent';

interface Props {
    id: string;
    sendNotification: typeof sendNotification;
}

function ClusterViewPage({ id, sendNotification }: Props) {
    const { data: cluster } = useSWR('/api/clusters/' + id, async url => fetcher(url), {
        refreshInterval: 10_000,
        refreshWhenHidden: false,
        refreshWhenOffline: false,
        suspense: false
    });

    return (
        <>
            <PageHeader title={ cluster?.name } breadcrumbs={ [
                {
                    href: '/clusters',
                    name: 'Clusters'
                },
                {
                    href: '/clusters/' + id,
                    name: cluster?.name
                }
            ] } />

            <PageContent isLoading={ false }>
                <ClusterViewPageContent cluster={ cluster } sendNotification={ sendNotification } />
            </PageContent>
        </>
    );
}

const mapDispatchToPropsParam = ({
    sendNotification
});

export default connect(null, mapDispatchToPropsParam)(ClusterViewPage);
