import * as React from 'react';
import './LoginButton.less';
import classNames from 'classcat';

interface Props {
    icon: React.ReactNode;
    loading: boolean;
    name: string;

    onClick(): void;
}

export default function LoginButton({ icon, loading, name, onClick }: Props) {
    const classes = classNames({
        'w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out': true,
        'opacity-50 cursor-not-allowed': loading
    });

    const content = loading
        ? <svg className="h-5 spinning" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
        : icon;

    return (
        <div>
            <span className="w-full inline-flex rounded-md shadow-sm">
                <button type="button"
                        onClick={ onClick }
                        className={ classes }
                        aria-label={ `Sign in with ${ name }` }>
                    { content } <span className="ml-3">{ name }</span>
                </button>
            </span>
        </div>
    )
}
