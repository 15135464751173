import * as React from 'react';
import { connect } from 'react-redux';
import auth from './auth';
import { RootState } from './store';
import Logo from './logo-square.png';
import LoginButton from './auth/LoginButton';
import { toggleAuthLoading } from './auth/authReducer';
import AppLoading from './AppLoading';
import { Account } from './account/accountReducer';
import GitHubIcon from './icons/GitHubIcon';
import GoogleIcon from './icons/GoogleIcon';

interface AuthIsLoadedProps {
    account: Account | null;
    children: React.ReactNode;
    loading: boolean;
    user: any;

    toggleAuthLoading(): void;
}

function AppAuthBoundary({ account, children, loading, toggleAuthLoading, user }: AuthIsLoadedProps) {
    if (user) {
        if (account) {
            return <>{ children }</>;
        }

        return <AppLoading />
    }

    // If there's a "state" query parameter, assume we've just been redirected back for login
    if (window.location.href.match(/[&?]state=/)) {
        auth.handleSignInRedirect();

        return <AppLoading />
    }

    const onClick = (provider: string) => {
        toggleAuthLoading();

        auth.signInWithProvider(provider);
    };

    return (
        <div className="min-h-screen bg-gray-700 flex flex-col items-center justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img alt="McKube: Instant, ephemeral Kubernetes" className="mx-auto h-20 w-auto" src={ Logo } />

                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-100">
                    Welcome to McKube
                </h2>
                <p className="mt-2 text-center text-sm leading-5 text-gray-300 max-w">
                    Instant, ephemeral Kubernetes
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm leading-5">
                            <span className="px-2 bg-white text-gray-500">
                                Sign in with
                            </span>
                        </div>
                    </div>

                    <div className="mt-6 grid grid-cols-2 gap-3">
                        <LoginButton loading={ loading } onClick={ () => onClick('github.com') } name="GitHub" icon={
                            <GitHubIcon className="h-5" />
                        } />
                        <LoginButton loading={ loading } onClick={ () => onClick('google.com') } name="Google" icon={
                            <GoogleIcon className="h-5" />
                        } />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    account: state.account.account,
    loading: state.auth.loading,
    user: state.auth.user
});

const mapDispatchToProps = ({
    toggleAuthLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(AppAuthBoundary);
