import * as React from 'react';
import { connect } from 'react-redux';
import './AccountPage.css';
import { RootState } from '../store';
import { Account, loadAccount } from './accountReducer';
import AccountForm from './AccountForm';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';

interface Props {
    account: Account | null;

    loadAccount(): void;
}

function AccountPage({ account, loadAccount }: Props) {
    if (account === null) {
        // TODO: This should never happen
        return null;
    }

    return (
        <>
            <PageHeader breadcrumbs={ [
                {
                    href: '/account',
                    name: 'Account'
                },
            ] } />

            <PageContent isLoading={ false }>
                <AccountForm loadAccount={ loadAccount } />
            </PageContent>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    account: state.account.account
});

const mapDispatchToProps = ({
    loadAccount
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
