import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classcat';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { RootState } from '../store';
import { AccountApiKey, loadApiKeys, revokeApiKey } from './accountReducer';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';
import Button from '../common/Button';
import Badge from '../common/Badge';
import Result from '../common/Result';
import { Link } from 'react-navi';
import SadFaceIcon from '../icons/SadFaceIcon';
import PlusIcon from '../icons/PlusIcon';
import Table from '../common/Table';

interface ModalProps {
    apiKey: AccountApiKey | null;
    loading: boolean;

    revokeApiKey(id: string): void;
    onClose(): void;
}

function Modal({ apiKey, loading, onClose, revokeApiKey }: ModalProps) {
    // TODO: This should never happen
    if (apiKey === null) {
        return null;
    }

    const onOk = async () => {
        await revokeApiKey(apiKey.id);
        await onClose();
    };

    return (
        <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
                className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
                role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div
                            className="mx-auto mt-1 mr-3 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                Are you sure?
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm leading-5 text-gray-500">
                                    Revoking this key means any client still using it will stop being able to
                                    communicate with McKube.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto ml-2">
                        <button type="button"
                                onClick={ () => onOk() }
                                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Revoke
                        </button>
                    </span>
                    <span className="flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button type="button"
                                onClick={ onClose }
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Cancel
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
}

interface Props {
    apiKeys: AccountApiKey[];
    apiKeysLoading: boolean;
    apiKeyRevoking: boolean;

    loadApiKeys(): void;

    revokeApiKey(id: string): void;
}

interface State {
    isModalOpen: boolean;
    selectedKey: AccountApiKey | null;
}

class AccountApiKeysPage extends React.Component<Props, State> {
    state = {
        isModalOpen: false,
        selectedKey: null
    }

    componentDidMount() {
        this.props.loadApiKeys();
    }

    onModalToggle = (selectedKey: AccountApiKey | null) => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            selectedKey: selectedKey
        });
    }

    render() {
        const { apiKeys, apiKeysLoading, apiKeyRevoking, revokeApiKey } = this.props;

        let content;
        if (apiKeys.length) {
            const columns = [
                {
                    key: 'name',
                    name: 'Name',
                    width: 'auto'
                },
                {
                    key: 'status',
                    name: 'Status',
                    width: '10rem',
                    render(row: AccountApiKey) {
                        if (row.isRevoked) {
                            return (
                                <Badge color="text-red-500">
                                    <svg className="mr-1.5 h-3 w-3" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path fillRule="evenodd"
                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                              clipRule="evenodd" />
                                    </svg>

                                    Revoked
                                </Badge>
                            )
                        }

                        return (
                            <Badge color="text-green-400">
                                <svg className="mr-1.5 h-3 w-3" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                Active
                            </Badge>
                        )
                    }
                },
                {
                    key: 'createdAt',
                    name: 'Created At',
                    width: '15rem',
                    render(row: AccountApiKey) {
                        return (
                            <time dateTime={ row.createdAt }
                                  title={ row.createdAt }>{ formatDistanceToNow(parseISO(row.createdAt), { addSuffix: true }) }</time>
                        )
                    }
                },
                {
                    key: 'actions',
                    width: '7rem',
                    render: (row: AccountApiKey) => {
                        const onClickRevoke = () => {
                            // If they key is already revoked, don't do anything
                            if (row.isRevoked) {
                                return;
                            }

                            this.onModalToggle(row);
                        };

                        const revokeClasses = classNames({
                            'text-red-500 hover:text-red-400 transition duration-150 ease-in-out': !row.isRevoked,
                            'text-gray-300 cursor-not-allowed': row.isRevoked,
                        });

                        return (
                            <button className={ revokeClasses } onClick={ onClickRevoke }>
                                Revoke
                            </button>
                        );
                    }
                }
            ]

            content = (
                <Table columns={ columns } data={ apiKeys } />
            );
        } else {
            content = (
                <Result
                    extra={
                        <div className="mt-4">
                            <Link href="/account/api-keys/new">
                                <Button>
                                    Create an API key
                                </Button>
                            </Link>
                        </div>
                    }
                    icon={ <SadFaceIcon /> }
                    title="No keys yet!"
                    subtitle={
                        <span>
                            Try creating a new key, and build something cool using <a
                            href="https://docs.mckube.dev/api/overview" rel="noopener noreferrer" target="_blank">our
                            API</a>
                        </span>
                    } />
            )
        }

        const modal = this.state.isModalOpen && this.state.selectedKey
            ? <Modal apiKey={ this.state.selectedKey } loading={ apiKeyRevoking } onClose={ () => this.onModalToggle(null) } revokeApiKey={ revokeApiKey } />
            : null;

        return (
            <>
                <PageHeader
                    breadcrumbs={[
                        {
                            href: '/account/api-keys',
                            name: 'API Keys'
                        }
                    ]}
                    extra={ [
                    <a key="view-documentation" href="https://docs.mckube.dev/api/overview" target="_blank" rel="noopener noreferrer">
                        <Button className="mr-3" type="secondary">
                            View Documentation
                        </Button>
                    </a>,
                    <Link key="new" href="/account/api-keys/new">
                        <Button>
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" />

                            New API key
                        </Button>
                    </Link>
                ] } />

                <PageContent isLoading={ apiKeysLoading }>
                    { modal }

                    { content }
                </PageContent>
            </>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    apiKeys: state.account.apiKeys,
    apiKeysLoading: state.account.apiKeysLoading,
    apiKeyRevoking: state.account.apiKeyRevoking,
});

const mapDispatchToProps = ({
    loadApiKeys,
    revokeApiKey
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountApiKeysPage);
