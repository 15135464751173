import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppLoading from './AppLoading';
import store from './store';
import AppWrapper from './AppWrapper';

import './index.css';

const app = (
    <Suspense fallback={ <AppLoading /> }>
        <Provider store={ store }>
            <AppWrapper />
        </Provider>
    </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));
