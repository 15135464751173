import * as React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';
import { RootState } from '../store';
import { AccountInvoice, loadInvoices } from './accountReducer';
import AccountBillingContent from './AccountBillingContent';

interface Props {
    invoices: AccountInvoice[];
    isLoading: boolean;

    loadInvoices(): void;
}

class AccountBillingPage extends React.Component<Props> {
    componentDidMount() {
        this.props.loadInvoices();
    }

    render() {
        const { invoices, isLoading } = this.props;

        return (
            <>
                <PageHeader breadcrumbs={ [
                    {
                        href: '/account',
                        name: 'Account'
                    },
                    {
                        href: '/account/billing',
                        name: 'Billing'
                    }
                ] } />

                <PageContent isLoading={ isLoading }>
                    <AccountBillingContent invoices={ invoices } />
                </PageContent>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    invoices: state.account.invoices,
    isLoading: state.account.invoicesLoading
});

const mapDispatchToProps = ({
    loadInvoices
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountBillingPage);
