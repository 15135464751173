import * as React from 'react';
import { useForm } from 'react-form';
import { useNavigation } from 'react-navi';
import Page from '../common/Page';
import { InviteTeamMemberRequest } from './teamReducer';
import Card from '../common/Card';
import Button from '../common/Button';
import InputField from '../common/InputField';
import { fetchAuthed } from '../utils/ajax';
import { handleError } from '../utils/errors';

function validateEmail(value: string) {
    if (!value) {
        return 'Please enter an email address to invite';
    }

    if (!value.includes('@')) {
        return 'Please enter a valid email address';
    }

    return false;
}

function TeamInvitePage() {
    const navigation = useNavigation();

    const { Form, meta: { isSubmitting, canSubmit } } = useForm({
        onSubmit: async (values: InviteTeamMemberRequest) => {
            try {
                await fetchAuthed('/api/team/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });

                // Reload the member list after inviting the member
                await navigation.navigate('/team');
            } catch (e) {
                handleError(e, 'inviting the team member');
            }
        }
    });

    return (
        <Page isLoading={ false } breadcrumbs={ [
            {
                href: '/team',
                name: 'Team'
            },
            {
                href: '/team/invite',
                name: 'Invite'
            }
        ] }>
            <Form>
                <Card>
                    <div>
                        <div className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                                    Email <span className="text-red-500">*</span>
                                </label>

                                <InputField field="email" validate={ validateEmail } />
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 border-t border-gray-200 pt-5">
                        <div className="flex justify-end">
                            <Button htmlType="submit" disabled={ !canSubmit } loading={ isSubmitting }>
                                Invite
                            </Button>
                        </div>
                    </div>
                </Card>
            </Form>
        </Page>
    );
}

export default TeamInvitePage;
