import * as React from 'react';
import { Link, useCurrentRoute, useNavigation } from 'react-navi';

export interface Breadcrumb {
    href: string;
    name: string;
}

interface Props {
    breadcrumbs: Breadcrumb[];
    extra?: React.ReactNode;
    title?: string;
}

function PageHeader({ breadcrumbs, extra, title }: Props) {
    const navigation = useNavigation();
    const currentRoute = useCurrentRoute();

    const breadcrumbContent = breadcrumbs.map((breadcrumb, i, array) => {
        const caret = array.length - 1 === i
            ? null
            : (
                <svg className="flex-shrink-0 mx-2 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd" />
                </svg>
            );

        return (
            <>
                <Link href={ breadcrumb.href }
                      className="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out">
                    { breadcrumb.name }
                </Link>
                { caret }
            </>
        )
    });

    const pageTitle = title
        ? title
        : currentRoute.title;

    const backButton = breadcrumbs.length < 2
        ? null
        : (
            <button onClick={ async () => await navigation.navigate(breadcrumbs[breadcrumbs.length - 2].href) }
                    className="flex items-center text-sm leading-5 font-medium text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out">
                <svg className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd" />
                </svg>
                Back
            </button>
        )

    return (
        <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <div className="md:flex md:items-center md:justify-between">
                    <div className="flex-1 min-w-0">
                        <div className="mb-2">
                            <nav className="sm:hidden">
                                { backButton }
                            </nav>
                            <nav className="hidden sm:flex items-center text-sm leading-5 font-medium">
                                { breadcrumbContent }
                            </nav>
                        </div>

                        <h1 className="text-lg leading-6 font-semibold text-gray-900">
                            { pageTitle }
                        </h1>
                    </div>
                    <div className="mt-4 flex md:mt-0 md:ml-4">
                        { extra }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default PageHeader;
