import store from '../store';
import { sendNotification } from '../notifications/notificationReducer';

export function handleError(e: any, action: string) {
    console.error(e);

    if (e.response && e.response.data && e.response.data.detail) {
        store.dispatch(sendNotification({
            duration: null,
            type: 'error',
            message: [
                `Something unknown went wrong while ${ action }.`,
                `${ e.response.data.title }: ${ e.response.data.detail }`
            ],
            title: 'Oops'
        }));
    } else {
        store.dispatch(sendNotification({
            duration: null,
            type: 'error',
            message: `Something unknown went wrong while ${ action }`,
            title: 'Oops'
        }));
    }
}
