import * as React from 'react';
import { useField, useForm } from 'react-form';
import { handleError } from '../utils/errors';
import { fetchAuthed } from '../utils/ajax';
import auth from '../auth';
import Button from '../common/Button';
import Card from '../common/Card';

function NameField({ defaultValue = '' }: { defaultValue: string }) {
    const { meta: { error, isTouched, isValidating }, getInputProps } = useField('name', {
        defaultValue: defaultValue,
        validatePristine: true,
        validate: function validateName(name: string) {
            if (!name || name === '') {
                return 'Please enter your name';
            }

            return false;
        }
    });

    return (
        <>
            <div className="mt-1 flex rounded-md shadow-sm">
                <input { ...getInputProps() }
                       className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
            </div>

            { isValidating ? (
                <em>Validating...</em>
            ) : isTouched && error ? (
                <p className="mt-2 text-red-500 text-xs">{ error }</p>
            ) : null }
        </>
    );
}

interface AccountFormProps {
    loadAccount(): void;
}

function AccountForm({ loadAccount }: AccountFormProps) {
    const { Form, meta: { isSubmitting, canSubmit } } = useForm({
        onSubmit: async (values: any) => {
            try {
                await fetchAuthed('/api/account', {
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST'
                });
            } catch (e) {
                handleError(e, 'updating your account');
                return;
            }

            // Now we successfully saved the account, reload the account to go to the right place
            await loadAccount();
        }
    });

    return (
        <Form>
            <Card>
                <div>
                    <div>
                        <div className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">
                                    Name <span className="text-red-500">*</span>
                                </label>

                                <NameField defaultValue={ auth.user.displayName } />
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                                    Email <span className="text-red-500">*</span>
                                </label>

                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <input value={ auth.user.email } className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 opacity-50" readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 border-t border-gray-200 pt-5">
                    <Button htmlType="submit" disabled={ !canSubmit } loading={ isSubmitting }>
                        Save
                    </Button>
                </div>
            </Card>
        </Form>
    );
}

export default AccountForm;
