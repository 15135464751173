import * as React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../common/PageHeader';
import PageContent from '../common/PageContent';
import { createCluster } from './clusterReducer';
import ClusterCreateForm from './ClusterCreateForm';

interface Props {
    onCreate(): void;
}

function ClusterCreatePage({ onCreate }: Props) {
    return (
        <>
            <PageHeader breadcrumbs={[
                {
                    href: '/clusters',
                    name: 'Clusters'
                },
                {
                    href: '/clusters/new',
                    name: 'New'
                },
            ]} />

            <PageContent isLoading={ false }>
                <ClusterCreateForm onCreate={ onCreate } />
            </PageContent>
        </>
    );
}

const mapDispatchToProps = ({
    onCreate: createCluster
});

export default connect(null, mapDispatchToProps)(ClusterCreatePage);
