import * as React from 'react';

interface Props {
    extra: React.ReactNode;
    icon: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
}

function Result({ extra, icon, title, subtitle }: Props) {
    return (
        <div className="flex flex-col items-center mx-8">
            <div className="w-20 h-20 m-5 text-blue-500">
                { icon }
            </div>

            <h3 className="text-2xl mb-2">{ title }</h3>

            <p className="text-gray-500 mb-1 text-sm">
                { subtitle }
            </p>

            { extra }
        </div>
    );
}

export default Result;
