import * as React from 'react';
import { splitFormProps, useField } from 'react-form';
import classNames from 'classcat';
import ErrorIcon from '../icons/ErrorIcon';

const InputField = React.forwardRef((props: any, ref) => {
    // Let's use splitFormProps to get form-specific props
    const [field, fieldOptions, rest] = splitFormProps(props);

    // Use the useField hook with a field and field options
    // to access field state
    const {
        meta: { error, isTouched, isValidating },
        getInputProps
    } = useField(field, fieldOptions);

    const inputClasses = classNames({
        'flex-1 form-input block w-full min-w-0 rounded-none rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5': true,
        'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red': isTouched && error
    });

    const errorClasses = classNames({
        'h-5 w-5 text-red-500': true,
        'block': isTouched && error,
        'hidden': !error
    });

    // Build the field
    return (
        <>
            <div className="mt-1 flex relative rounded-md shadow-sm">
                <input { ...getInputProps({ ref, ...rest }) }
                       className={ inputClasses } />

                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ErrorIcon className={  errorClasses } />
                </div>
            </div>

            { isValidating ? (
                <em>Validating...</em>
            ) : isTouched && error ? (
                <p className="mt-2 text-sm text-red-600">{ error }</p>
            ) : null }
        </>
    );
});

export default InputField;
