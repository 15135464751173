import React from 'react';
import Loader from './common/Loader';

export default function AppLoading() {
    return (
        <div className="min-h-screen bg-gray-700">
            <div className="flex items-center justify-center pt-48">
                <Loader color="#fff" />
            </div>
        </div>
    );
}
