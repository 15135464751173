import * as React from 'react';
import { Router } from 'react-navi';
import { connect } from 'react-redux';
import App from './App';
import store, { RootState } from './store';
import auth from './auth';
import { login, logout } from './auth/authReducer';
import { Account, AccountTeam, loadAccount } from './account/accountReducer';
import AppNotifications from './AppNotifications';
import { routes } from './router';

auth.listen((user: any) => {
    if (user) {
        // TODO: Optimise all these calls, as they could happen multiple times, not just "on login"
        store.dispatch(login(user));
        store.dispatch(loadAccount());
    } else {
        store.dispatch(logout());
    }
});

interface Props {
    account: Account | null;
    currentTeam?: AccountTeam;
}

function AppWrapper({ account, currentTeam }: Props) {
    return (
        <Router routes={ routes } context={{ account, currentTeam }}>
            <AppNotifications />

            <App />
        </Router>
    );
}

const mapStateToProps = (state: RootState) => ({
    account: state.account.account,
    currentTeam: state.account.currentTeam
});

export default connect(mapStateToProps)(AppWrapper);
