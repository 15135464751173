import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { handleError } from '../utils/errors';
import { mutate } from 'swr';
import { loadAccount } from '../account/accountReducer';
import { fetchAuthed } from '../utils/ajax';
import { sendNotification } from '../notifications/notificationReducer';
import { RootState } from '../store';

export interface ClusterState {
    clusterTerminating: boolean;
}

const initialState: ClusterState = {
    clusterTerminating: false
}

export interface CreateClusterPayload {
    name: string;
    duration: number;
}

export const createCluster = createAsyncThunk<void, void, { state: RootState }>('CreateCluster', async (payload, thunk) => {
    thunk.dispatch(sendNotification({
        duration: 3000,
        message: 'Your cluster is now provisioning!',
        title: 'Provisioned',
        type: 'success',
    }));

    thunk.dispatch(loadAccount());
});

export const terminateCluster = createAsyncThunk<void, string>('TerminateCluster', async (payload, thunk) => {
    try {
        await fetchAuthed('/api/clusters/' + payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        });

        // Make sure SWR reloads the cluster list
        await mutate('/api/clusters');
    } catch (e) {
        handleError(e, 'terminating your cluster');
    }
});

export default createReducer(initialState, builder => builder
    .addCase(terminateCluster.pending, (state, action) => {
        return {
            ...state,
            clusterTerminating: true
        }
    })
    .addCase(terminateCluster.fulfilled, (state, action) => {
        return {
            ...state,
            clusterTerminating: false
        }
    })
);
