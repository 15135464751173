import auth from '../auth';
import store from '../store';

const HEADER_TEAM = 'X-McKube-Team';

export const fetcher = (url: string) => {
    return fetchAuthed(url);
};

export function fetchAuthed(resource: string, init: any = null) {
    const { account } = store.getState();

    if (account.currentTeam) {
        if (init) {
            if (init.headers) {
                init.headers[HEADER_TEAM] = account.currentTeam.id;
            } else {
                init.headers = {
                    [HEADER_TEAM]: account.currentTeam.id
                }
            }
        } else {
            init = {
                headers: {
                    [HEADER_TEAM]: account.currentTeam.id
                }
            }
        }
    }

    return auth.authorizedRequest(resource, init)
        .then(async (response: Response) => {
            if (response.ok) {
                if (response.status === 204) {
                    return;
                }

                return response.json();
            }

            // eslint-disable-next-line no-throw-literal
            throw {
                response: {
                    data: await response.json(),
                    status: response.status
                }
            };
        });
};
