import * as React from 'react';
import PageHeader, { Breadcrumb } from './PageHeader';
import PageContent from './PageContent';

interface Props {
    breadcrumbs: Breadcrumb[];
    children: React.ReactNode;
    extra?: React.ReactNode;
    isLoading: boolean;
}

function Page({ breadcrumbs, children, extra, isLoading }: Props) {
    return (
        <>
            <PageHeader breadcrumbs={ breadcrumbs } extra={ extra } />

            <PageContent isLoading={ isLoading }>
                { children }
            </PageContent>
        </>
    );
}

export default Page;
