import React from 'react';
import Plans from './Plans';
import Card from '../common/Card';
import { fetchAuthed } from '../utils/ajax';
import { AccountPlan } from './accountReducer';
import { useNavigation } from 'react-navi';

interface Props {
    plans: AccountPlan[];

    loadAccount(): void;
}

function AccountTeamPlansContent({ loadAccount, plans }: Props) {
    const navigation = useNavigation();

    const onClickSubscribe = async (plan: string) => {
        // @ts-ignore
        let chargebee = window.Chargebee.getInstance();

        chargebee.openCheckout({
            hostedPage: function () {
                return fetchAuthed('/api/chargebee/create-checkout-session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        plan: plan
                    })
                });
            },
            success: async function() {
                // Manually refresh the users's subscription details on the backend in case the webhooks haven't come through yet
                await fetchAuthed('/api/chargebee/refresh-active-subscription', {
                    method: 'POST'
                });

                // Refresh the user's account info so the UI knows they're now subscribed
                await loadAccount();
                await navigation.navigate('/clusters');
            }
        });
    };

    return (
        <Card>
            <div className="mb-6">
                <h3 className="text-xl font-semibold">Choose a Plan</h3>
                <p className="text-sm text-gray-700">
                    Your subscription will start right away
                </p>
            </div>

            <Plans onClick={ onClickSubscribe } plans={ plans } />
        </Card>
    );
}

export default AccountTeamPlansContent;
