import * as React from 'react';
import { Link } from 'react-navi';
import { connect } from 'react-redux';
import { formatDistanceToNow, parseISO } from 'date-fns';
import Page from '../common/Page';
import CreditCardIcon from '../icons/CreditCardIcon';
import Button from '../common/Button';
import { fetchAuthed } from '../utils/ajax';
import { loadAccount } from '../account/accountReducer';
import PlusIcon from '../icons/PlusIcon';
import { loadMembers, removeMember, TeamMember } from './teamReducer';
import { RootState } from '../store';
import Table from '../common/Table';

interface Props {
    members: TeamMember[];
    membersLoading: boolean;

    loadAccount(): void;
    loadMembers(): void;
    removeMember(id: string): void;
}

class TeamPage extends React.Component<Props> {
    componentDidMount() {
        this.props.loadMembers();
    }

    render() {
        const { loadAccount, members, membersLoading, removeMember } = this.props;

        const onClickPortal = () => {
            // @ts-ignore
            const chargebee = window.Chargebee.getInstance();

            chargebee.setPortalSession(function () {
                return fetchAuthed('/api/chargebee/create-portal-session', {
                    method: 'POST'
                });
            });

            chargebee.createChargebeePortal().open({
                close: async function () {
                    // Manually refresh the users's subscription details on the backend in case the webhooks haven't come through yet
                    await fetchAuthed('/api/chargebee/refresh-cancelled-subscription', {
                        method: 'POST'
                    });

                    await loadAccount();
                }
            });
        };

        const columns = [
            {
                key: 'name',
                name: 'Name',
                width: 'auto'
            },
            {
                key: 'email',
                name: 'Email',
                width: 'auto'
            },
            {
                key: 'addedAt',
                name: 'Added At',
                width: '15rem',
                render: (row: TeamMember) => {
                    return (
                        <time dateTime={ row.addedAt }
                              title={ row.addedAt }>{ formatDistanceToNow(parseISO(row.addedAt), { addSuffix: true }) }</time>
                    )
                }
            },
            {
                key: 'actions',
                width: '7rem',
                render: (row: TeamMember) => {
                    const onClickRemove = () => {
                        removeMember(row.id);
                    };

                    return (
                        <button className='text-red-500 hover:text-red-400 transition duration-150 ease-in-out' onClick={ onClickRemove }>
                            Remove
                        </button>
                    );
                }
            }
        ];

        return (
            <Page isLoading={ membersLoading } breadcrumbs={ [
                {
                    href: '/team',
                    name: 'Team'
                }
            ] } extra={[
                <Button onClick={ onClickPortal } type="secondary" className="mr-3">
                    <CreditCardIcon className="-ml-1 mr-2 h-5 w-5" /> Billing
                </Button>,
                <Link href="/team/invite">
                    <Button>
                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" /> Invite user
                    </Button>
                </Link>
            ]}>
                <Table columns={ columns } data={ members } />
            </Page>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    members: state.team.members,
    membersLoading: state.team.membersLoading,
});

const mapDispatchToProps = ({
    loadAccount,
    loadMembers,
    removeMember
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamPage);
