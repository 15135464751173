import * as React from 'react';
import { Link } from 'react-navi';

interface Props {
    children: React.ReactNode;
    href?: string;

    onClick?(): void;
}

function DropdownItem({ children, href = '', onClick = () => null }: Props) {
    return (
        <Link href={ href } className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={ onClick }>
            { children }
        </Link>
    );
}

export default DropdownItem;
