import { CSSProperties, ReactNode } from 'react';

export interface Column<D> {
    key: string;
    name?: string;
    width?: string;

    render?(data: D): ReactNode;
}

export interface TableHeaderProps {
    style?: CSSProperties;
}

export interface TableHeader {
    getHeaderProps(): TableHeaderProps;
    render(): ReactNode;
}

export interface TableRowCell {
    render(): ReactNode;
}

export interface TableRow {
    cells: TableRowCell[];
    index: number;
}

export interface UseTableResult<D extends object = {}> {
    headers: TableHeader[];
    rows: TableRow[];
}

export interface UseTableProps<D extends object> {
    columns: Column<D>[];
    data: D[];
}

// TODO: Extract this into a package?
export default function useTable<D extends object = {}>({ columns, data }: UseTableProps<D>): UseTableResult<D> {
    return {
        headers: columns.map(column => {
            return {
                getHeaderProps(): TableHeaderProps {
                    return {
                        style: {
                            width: column.width
                        }
                    }
                },
                render(): ReactNode {
                    return column.name
                }
            }
        }),
        rows: data.map((row, i) => {
            return {
                cells: columns.map(column => {
                    return {
                        render(): ReactNode {
                            if (column.render) {
                                return column.render(row);
                            } else {
                                // TODO: Figure out this typing issue
                                // @ts-ignore
                                return row[column.key];
                            }
                        }
                    }
                }),
                index: i
            }
        })
    };
}
