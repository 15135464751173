import { configureStore } from '@reduxjs/toolkit';
import clusterReducer, { ClusterState } from './clusters/clusterReducer';
import accountReducer, { AccountState } from './account/accountReducer';
import authReducer, { AuthState } from './auth/authReducer';
import notificationsReducer, { NotificationsState } from './notifications/notificationReducer';
import teamReducer, { TeamState } from './team/teamReducer';

export interface RootState {
    auth: AuthState;
    account: AccountState;
    cluster: ClusterState;
    notifications: NotificationsState;
    team: TeamState;
}

const rootReducer = {
    auth: authReducer,
    account: accountReducer,
    cluster: clusterReducer,
    notifications: notificationsReducer,
    team: teamReducer,
};

export default configureStore({
    reducer: rootReducer
});
