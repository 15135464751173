import * as React from 'react';
import Alert from '../common/Alert';
import SyntaxHighlighter from './SyntaxHighlighter';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '../common/Button';
import { Cluster } from './Cluster';
import { sendNotification } from '../notifications/notificationReducer';
import Loader from '../common/Loader';
import ClipboardIcon from '../icons/ClipboardIcon';
import Card from '../common/Card';

interface Props {
    cluster: Cluster;

    sendNotification: typeof sendNotification;
}

function ClusterViewPageContent({ cluster, sendNotification }: Props) {
    if (!cluster) {
        return (
            <div className="flex w-full my-16 items-center justify-center">
                <Loader color="#252f3f" />
            </div>
        )
    }

    const sendClipboardNotification = (message: string) => {
        sendNotification({
            duration: 3000,
            title: 'Copied',
            message: message,
            type: 'success'
        });
    }

    const downloadKubeconfig = (yaml: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(yaml));
        element.setAttribute('download', 'kubeconfig.yaml');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    return (
        <Card>
            <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Connect
                </h3>
                <p className="max-w-2xl text-sm leading-6 text-gray-500">
                    Some of the ways to connect with your cluster
                </p>
            </div>

            <div className="mt-5 border-t border-gray-200 pt-5">
                <dl>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            Ingress
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <Alert
                                className="mb-4"
                                message="This address will only be accessible if you have an ingress controller running" />

                            <a href={ `http://${ cluster.address }` } target="_blank" rel="noopener noreferrer">
                                { cluster.address }
                            </a>
                        </dd>
                    </div>
                    <div
                        className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            kubectl
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <p className="mb-2">
                                To quickly and easily connect with your cluster without affecting your local
                                machine's <code>KUBECONFIG</code>, use a temporary one by following the below:
                            </p>

                            <ol className="list-decimal list-inside">
                                <li className="my-2">Make sure you have <a
                                    href="https://kubernetes.io/docs/tasks/tools/install-kubectl/"
                                    rel="noopener noreferrer"
                                    target="_blank"><code>kubectl</code></a> installed
                                </li>
                                <li className="my-2">
                                    Create a temporary file with your cluster's <code>KUBECONFIG</code>, and
                                    use
                                    it in the current shell:

                                    <SyntaxHighlighter
                                        code={ `export KUBECONFIG=$(mktemp) && echo '${ JSON.stringify(JSON.parse(cluster.kubeConfigJson || '')) }' > $KUBECONFIG` }
                                    />
                                </li>
                                <li className="my-2">
                                    Ensure your cluster is set up correctly:

                                    <SyntaxHighlighter
                                        code={ `$ kubectl get nodes

NAME                                             STATUS   ROLES    AGE    VERSION
node-08bce15e-4d3d-4856-9a81-f82ec2c3221a        Ready    master   4m     v1.18.3` }
                                    />
                                </li>
                            </ol>
                        </dd>
                    </div>
                    <div
                        className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            kubeconfig
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <p>If you'd like to configure your Kubernetes client manually, you can copy or download
                                the <code>KUBECONFIG</code> below.</p>

                            <div className="mt-4">
                                {/* TODO: Extract this as a common component */ }
                                <CopyToClipboard text={ cluster.kubeConfigJson || '' }
                                                 onCopy={ () => sendClipboardNotification('Copied JSON to clipboard') }>
                                    <Button className="mr-3" type="secondary">
                                        <ClipboardIcon className="h-5 mr-2" />

                                        <span>Copy as JSON</span>
                                    </Button>
                                </CopyToClipboard>

                                <CopyToClipboard text={ cluster.kubeConfigYaml || '' }
                                                 onCopy={ () => sendClipboardNotification('Copied YAML to clipboard') }>
                                    <Button className="mr-3" type="secondary">
                                        <ClipboardIcon className="h-5 mr-2" />

                                        <span>Copy as YAML</span>
                                    </Button>
                                </CopyToClipboard>

                                <Button type="secondary"
                                        onClick={ () => downloadKubeconfig(cluster.kubeConfigYaml || '') }>
                                    <svg className="h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd"
                                              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                              clip-rule="evenodd" />
                                    </svg>

                                    <span>Download</span>
                                </Button>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>
        </Card>
    );
}

export default ClusterViewPageContent;
