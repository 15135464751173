import * as React from 'react';
import { Transition } from 'react-transition-group';
import Loader from './Loader';

const duration = 100;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    width: '100%'
};

const transitionStyles: any = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
};

interface FadeProps {
    children: React.ReactNode;
    in: any;
}

const Fade = ({ children, in: inProp }: FadeProps) => (
    <Transition in={ inProp } timeout={ duration }>
        { (state) => (
            <div style={ {
                ...defaultStyle,
                ...transitionStyles[state]
            } }>
                { children }
            </div>
        ) }
    </Transition>
);

interface Props {
    className?: string;
    isLoading: boolean;
}

interface State {
    showSpinner: boolean;
}

class Loadable extends React.Component<Props, State> {
    state = {
        showSpinner: false
    };

    componentDidMount = () => {
        this.createSpinnerTimeout();
    };

    componentWillReceiveProps = (nextProps: Props) => {
        if (nextProps.isLoading === false) {
            this.setState({
                showSpinner: false
            });
        }

        if (nextProps.isLoading === true) {
            this.createSpinnerTimeout();
        }
    };

    createSpinnerTimeout = () => {
        setTimeout(() => {
            if (this.props.isLoading) {
                this.setState({
                    showSpinner: true
                });
            }
        }, duration + 50);
    };

    render() {
        let spinner = null;

        if (this.state.showSpinner) {
            spinner = (
                <div className="flex flex-col items-center h-56 justify-center">
                    <Loader />
                </div>
            )
        }

        return (
            <div>
                <Fade in={ this.state.showSpinner }>
                    { spinner }
                </Fade>

                <Fade in={ !this.props.isLoading }>
                    <div className={ this.props.className }>
                        { this.props.children }
                    </div>
                </Fade>
            </div>
        )
    }
}

export default Loadable;
